<template>
  <div class="main-page">
    <el-form
      size="small"
      :model="ActivityForm"
      label-position="right"
      label-width="80px"
      class="form small-form"
      v-if="ActivityForm"
      @submit.native.prevent
    >
      <div v-if="isCycOrganization" class="modular">
        <p class="modular-title">互动设置</p>
        <div class="modular-content">
          <el-form-item label="发帖互动：" prop="circle_post_enable">
            <el-radio-group v-model="ActivityForm.circle_post_enable">
              <el-radio :label="1">开启</el-radio>
              <el-radio :label="0">关闭</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
      </div>
      <!-- 线上活动才显示 -->
      <template v-if="ActivityForm.type === 1">
       <!--   <div class="modular">
          <p class="modular-title">规则设置</p>
          <div class="modular-content">
            <el-form-item label="位置限制：" prop="address_limit">
              <el-switch
                :active-value="1"
                :inactive-value="0"
                v-model="ActivityForm.address_limit"
              >
              </el-switch>
              <p class="info">
                <i class="el-icon-info"></i
                >开启后，只允许已选择地区的会员报名活动
              </p>
            </el-form-item> 
            <el-form-item
              v-if="ActivityForm.address_limit"
              prop="address_limit"
            >
              <el-cascader
                :options="CityDataList"
                :props="optionProp"
                v-model="ActivityForm.limited_addresses"
                collapse-tags
                clearable
                placeholder="请选择地理位置"
              />
            </el-form-item>
           <el-form-item label="其他限制：">
              <div class="flex-col" style="max-width: 350px">
                <el-checkbox
                  :true-label="1"
                  :false-label="0"
                  v-model="ActivityForm.is_pay_dues_join"
                >
                  <span> 未缴纳会费不可参与报名 </span>
                </el-checkbox> 
                 <el-checkbox
                  :true-label="1"
                  :false-label="0"
                  v-model="ActivityForm.can_visitor_join"
                >
                  {{ "非会员用户可报名" | saasVersionKeyword }}
                </el-checkbox>
                <div v-if="ActivityForm.can_visitor_join">
                  <el-form-item
                    style="margin-left: 24px"
                    :label="handleSaaSVersionKeyword('非会员报名审核')"
                    label-width="95px"
                  > 
                    <el-radio
                      style="margin-left: 20px"
                      v-model="ActivityForm.check_visitor_join"
                      :label="0"
                      >无需审核
                    </el-radio>
                    <el-radio
                      v-model="ActivityForm.check_visitor_join"
                      :label="1"
                      >需人工审核</el-radio
                    >
                  </el-form-item>
                </div>
                <el-checkbox
                  :true-label="1"
                  :false-label="0"
                  v-model="ActivityForm.is_info_completed"
                >
                  非会员需要填写姓名才可报名
                </el-checkbox>
                <p class="info" style="margin-left: 25px; margin-top: 0">
                  <i class="el-icon el-icon-info" />
                  {{ handleSaaSVersionKeyword("非会员不受该资料限制") }}
                  <br />
                  <i class="el-icon el-icon-info" /> 游客需填写姓名才可报名
                </p>
              </div>  
            </el-form-item>  
          </div>
        </div>-->
        <div class="modular">
          <p class="modular-title">帮助设置</p>
          <div class="modular-content">
            <el-form-item label="秘书二维码：" prop="admin_user_qrcode">
              <SingleMediaWall
                v-model="ActivityForm.admin_user_qrcode"
                :width="200"
                :height="200"
                ratio="200;200"
                :show-cut="true"
              >
                <p slot="info" class="img-tips">建议上传尺寸比例为 300*300</p>
              </SingleMediaWall>
            </el-form-item>
            <el-form-item label="问题答疑：" prop="questions">
              <ActivityQuestions
                v-if="ActivityForm.questions"
                :value="ActivityForm.questions"
              ></ActivityQuestions>
            </el-form-item>
          </div>
        </div>
        <div v-if="isActivityPhoto" class="modular">
          <p class="modular-title">相册关联</p>
          <div class="modular-content">
            <el-form-item label="选择相册：" prop="photo_activity_id">
              <!-- 相册选择 -->
              <SelectAlbum
                v-model="ActivityForm.photo_activity_id"
              ></SelectAlbum>
            </el-form-item>
          </div>
        </div>
        <div class="modular">
          <p class="modular-title">活动群设置</p>
          <div class="modular-content">
            <el-form-item label="群聊二维码：" prop="chat_qrcode">
              <SingleMediaWall
                v-model="ActivityForm.chat_qrcode"
                :width="200"
                :height="200"
                ratio="200:200"
                :show-cut="true"
              >
                <p slot="info" class="img-tips">建议上传尺寸比例为 300*300</p>
              </SingleMediaWall>
            </el-form-item>
            <el-form-item label="提醒时间：" prop="chat_qrcode_remind_time">
              <el-date-picker
                v-model="ActivityForm.chat_qrcode_remind_time"
                type="datetime"
                value-format="timestamp"
                placeholder="选择日期时间"
              >
              </el-date-picker>
              <p class="info">
                <i class="el-icon-info"></i
                >因群聊二维码会过期，设置提醒时间来提醒管理员更换
              </p>
            </el-form-item>
          </div>
        </div>
        <div class="modular">
          <p class="modular-title">停车票上传</p>
          <div class="modular-content">
            <el-form-item label="地址管理：" prop="parking_tickets">
              <ParkingTickets
                v-model="ActivityForm.parking_tickets"
              ></ParkingTickets>
            </el-form-item>
          </div>
        </div>
        <div class="modular">
          <p class="modular-title">备注&说明</p>
          <div class="modular-content">
            <el-form-item label="着装要求：" prop="dress">
              <el-input
                type="textarea"
                v-model="ActivityForm.dress"
                placeholder="请填写着装要求"
                rows="5"
                maxlength="200"
                show-word-limit
              ></el-input>
              <p class="info">
                <i class="el-icon-info"></i>活动状态未开始、报名中才显示
              </p>
            </el-form-item>
            <el-form-item label="备注说明：" prop="remark">
              <el-input
                type="textarea"
                v-model="ActivityForm.remark"
                placeholder="请填写备注说明"
                rows="5"
                maxlength="200"
                show-word-limit
              ></el-input>
              <p class="info">
                <i class="el-icon-info"></i>活动状态未开始、报名中才显示
              </p>
            </el-form-item>
          </div>
        </div>
      </template>
      <div class="modular">
        <p class="modular-title">分享配置</p>
        <div class="modular-content">
          <el-form-item label="分享标题：" prop="share_title">
            <el-input
              type="text"
              v-model="ActivityForm.share_title"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item label="分享图片：" prop="share_image">
            <SingleMediaWall
              v-model="ActivityForm.share_image"
              :width="140"
              :height="140"
              ratio="5:4"
              :show-cut="true"
            >
              <p slot="info" class="img-tips">
                建议上传尺寸比例为1500*1200，不上传图片时，默认使用系统自带图片
              </p>
            </SingleMediaWall>
          </el-form-item>
        </div>
      </div>
    </el-form>
  </div>
</template>
<script>
import SingleMediaWall from "@/modules/common/components/SingleMediaWall.vue";
import ActivityQuestions from "@/modules/activity/components/ActivityList/ActivityQuestions";
import SelectAlbum from "@/modules/activity/components/ActivityList/SelectAlbum";
import ParkingTickets from "@/modules/activity/components/ActivityList/ParkingTickets";
export default {
  model: {
    prop: "form",
    event: "updateForm",
  },
  props: ["form", "isNext", "saveDetail", "saveLoading"],
  data() {
    return {

     
    };
  },
  created() {
  },
  watch: {
    form: {
      handler(val) {
        console.log(val)
      },
      deep:true,
      immediate:true
    }
  },
  methods: {
   
  },
  computed: {
    ActivityForm: {
      get() {
        return this.form;
      },
      set(val) {
        this.$emit("updateForm", val);
      },
    },
    is_next: {
      get() {
        return this.isNext;
      },
      set(val) {
        this.$emit("update:next", val);
      },
    },
    isCycOrganization() {
      return this.$store.getters.tenant.features.includes('cyc_organization')
    },
    isActivityPhoto() {
      // activity_photo
      return this.$store.getters.tenant.features.includes('activity_photo')
    }
  },
  components: {
    SingleMediaWall,
    ActivityQuestions,
    SelectAlbum,
    ParkingTickets,
  },
};
</script>

<style lang="scss" scoped>
.img-tips {
  text-align: center;
  font-size: 12px;
  color: #c0c4cc;
  line-height: 24px;
}
.modular-content {
  margin-left: 11px;
}
</style>
